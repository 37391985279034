import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InquireHero from "../components/inquire/inquireHero"
import InquireForm from "../components/inquire/inquireForm"

export const InquirePageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description, hero },
    },
  },
  location,
}) => {
  return (
    <Layout location={location}>
      <SEO title={title} description={description} pathname={slug} />
      <InquireHero data={hero} />
      <InquireForm />
    </Layout>
  )
}

export default InquirePageTemplate

export const pageQuery = graphql`
  query InquirePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "inquire-page" } }) {
      frontmatter {
        slug
        title
        description
        hero {
          heading
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          image_alt
        }
      }
    }
  }
`

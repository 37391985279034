import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import queryString from "query-string"

import ErrorIcon from "../errorIcon"

const InquireForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [nameSubmitted, setNameSubmitted] = useState("")
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
    reset,
  } = useForm({
    mode: "onBlur",
  })
  const [recaptchaToken, setRecaptchaToken] = useState()
  const onSubmit = async (
    { name, email, project, budget, details, botField },
    e
  ) => {
    e.preventDefault()
    if (recaptchaToken === undefined || recaptchaToken === "") {
      return setError("recaptcha", "invalid", "Google Recaptcha Error")
    }

    try {
      const response = await fetch(
        `/.netlify/functions/inquire-form-submitted`,
        {
          method: "POST",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: queryString.stringify({
            "form-name": e.target.getAttribute("name"),
            name,
            email,
            project,
            budget,
            details,
            botField,
            recaptchaToken,
          }),
        }
      )

      const data = await response.json()

      if (response.status === 422) {
        if (data.errors.recaptchaToken) {
          return setError("recaptcha", "invalid", "Google Recaptcha Error")
        } else if (data.errors.formName) {
          return setError("form-name", "invalid", "There was a form error")
        } else if (data.errors.botField) {
          return setError("botField", "invalid", "You are spam, stay away")
        } else {
          setError()
        }
        window.grecaptcha.reset()
        setSubmitted(false)
      }

      if (response.status === 200) {
        setNameSubmitted(data)
        reset()
        setSubmitted(true)
      }
    } catch (err) {
      console.error(`error: ${err}`)
      setError("submit", "submitError", err.message)
    }
  }

  return (
    <>
      {submitted ? (
        <div className="container mx-auto max-w-3xl px-4">
          {nameSubmitted.firstName.charAt(0).toUpperCase() +
            nameSubmitted.firstName.slice(1)}
          , thanks for reaching out. I'll be in touch shortly!
        </div>
      ) : (
        <div className="relative">
          {isSubmitting && (
            <div className="absolute z-10 inset-0 flex justify-center items-center">
              <div className="absolute inset-0 flex justify-center items-center bg-white rounded opacity-75" />
              <svg
                id="inquire-form-spinner"
                className="absolute z-20"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="45" />
              </svg>
            </div>
          )}
          <form
            className="relative container mx-auto max-w-3xl px-4"
            onSubmit={handleSubmit(onSubmit)}
            name="Contact"
          >
            <div className="hidden">
              <label htmlFor="contact-form-bot-field">
                Don't fill this out:
                <input
                  id="contact-form-bot-field"
                  type="text"
                  name="botField"
                  ref={register}
                />
              </label>
            </div>
            <div>
              <div>
                <div>
                  <div className="border-gray-200">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-md font-semibold leading-5 text-gray-700"
                        >
                          Name
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="name"
                            className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md ${
                              errors.name
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            type="text"
                            name="name"
                            ref={register({
                              required: true,
                              minLength: 2,
                              maxlength: 50,
                            })}
                          />
                          {errors.name && <ErrorIcon />}
                        </div>
                        {errors.name && (
                          <p className="mt-2 text-xs text-red-600">
                            Name is required.
                          </p>
                        )}
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-md font-semibold leading-5 text-gray-700"
                        >
                          Email Address
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="email"
                            type="email"
                            className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md ${
                              errors.email
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            name="email"
                            ref={register({
                              required: true,
                              pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            })}
                          />
                          {errors.email && <ErrorIcon />}
                        </div>
                        {errors.email && (
                          <p className="mt-2 text-xs text-red-600">
                            Email is required.
                          </p>
                        )}
                        <span className="text-xs text-gray-600">
                          *I'll never share you email with anyone else.
                        </span>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="project"
                          className="block text-md font-semibold leading-5 text-gray-700"
                        >
                          Type of Project
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <select
                            id="project"
                            className={`mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 rounded-md ${
                              errors.project
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            ref={register({
                              required: true,
                              validate: (value) =>
                                value === "Custom Solution" ||
                                value === "Digital Marketing" ||
                                value === "Jamstack Website" ||
                                value === "WordPress Implementation",
                            })}
                            name="project"
                          >
                            <option defaultValue value="">
                              Select an Option
                            </option>
                            <option value="Custom Solution">
                              Custom Solution
                            </option>
                            <option value="Digital Marketing">
                              Digital Marketing
                            </option>
                            <option value="Jamstack Website">
                              Jamstack Website
                            </option>
                            <option value="WordPress Implementation">
                              WordPress Implementation
                            </option>
                          </select>
                          {errors.project && <ErrorIcon />}
                        </div>
                        {errors.project && (
                          <p className="mt-2 text-xs text-red-600">
                            Project is required.
                          </p>
                        )}
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="budget"
                          className="block text-md font-semibold leading-5 text-gray-700"
                        >
                          Budget
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <select
                            id="budget"
                            className={`mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 rounded-md ${
                              errors.project
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            ref={register({
                              required: true,
                              validate: (value) =>
                                value === "$1,500 - $5,000" ||
                                value === "$5,000 - $10,000" ||
                                value === "$10,000+",
                            })}
                            name="budget"
                          >
                            <option defaultValue value="">
                              Select an Option
                            </option>
                            <option value="$1,500 - $5,000">
                              $1,500 - $5,000
                            </option>
                            <option value="$5,000 - $10,000">
                              $5,000 - $10,000
                            </option>
                            <option value="$10,000+">$10,000+</option>
                          </select>
                          {errors.budget && <ErrorIcon />}
                        </div>
                        {errors.budget && (
                          <p className="mt-2 text-xs text-red-600">
                            Budget is required.
                          </p>
                        )}
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="details"
                          className="block text-md font-semibold leading-5 text-gray-700"
                        >
                          Details
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <textarea
                            id="details"
                            rows="5"
                            className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md ${
                              errors.details
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            name="details"
                            ref={register({
                              required: true,
                              minLength: 2,
                              maxLength: 2000,
                            })}
                          ></textarea>
                          {errors.details && <ErrorIcon />}
                        </div>
                        {errors.details && (
                          <p className="mt-2 text-xs text-red-600">
                            Details are required.
                          </p>
                        )}
                      </div>

                      <GoogleReCaptcha
                        onVerify={(token) => {
                          setRecaptchaToken(token)
                        }}
                      />

                      <div>
                        {errors.recaptcha && (
                          <div className="flex mb-2">
                            <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <svg
                                className="h-5 w-5 text-red-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <span className="text-red-900">
                              Google Recaptcha Error
                            </span>
                          </div>
                        )}
                        {errors && errors.submit && (
                          <div className="flex justify-center mb-2">
                            <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <svg
                                className="h-5 w-5 text-red-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <span className="text-red-900">
                              {errors.submit.message}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="sm:col-span-6 text-center">
                        <div className="ml-3 inline-flex rounded-md shadow-sm mt-4">
                          <button
                            type="submit"
                            className={`${
                              isSubmitting
                                ? "cursor-not-allowed"
                                : "hover:bg-brand-500"
                            } cursor-pointer bg-transparent hover:bg-dark text-lg text-dark font-semibold hover:text-white py-3 px-5 border border-dark rounded transition duration-300 ease-in-out`}
                            onClick={() => setSubmitted(false)}
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="text-sm md:text-md mt-8">
                          This form is protected by reCAPTCHA and the Google{" "}
                          <a
                            className="underline hover:opacity-75"
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            className="underline hover:opacity-75"
                            href="https://policies.google.com/terms"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            Terms of Service
                          </a>{" "}
                          apply.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default InquireForm
